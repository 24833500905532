.no-page-found{
    display: flex;
    justify-content: center;
}
.error-code{
    margin-top: 30px;
}
.error-message{
    font-size: 100px;
}
@media screen and (max-width: 600px) {
    .error-code{
        font-size: 150px;
    }
    .error-message{
        font-size: 40px;
        color: black;
    }
}
