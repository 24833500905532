.developmentprocess-section{
    font-weight: 100;
}
.our-stories{
    display:flex;
    font-family:'Geologica';
    font-size: 90px !important;
    font-weight:800;
}
.about-us-description{
    display:"flex";
    justify-content:"center";
    font-size:20px;
}
@media screen and (max-width: 770px) {
    .developmentprocess-section{
        font-weight: 100;
        margin-top: 0px;
    }
    .our-stories{
        font-size:50px !important;
    }
    .about-us-description{
        font-size:15px;
    }
}

@media screen and (max-width: 570px) {
    .developmentprocess-section{
        font-weight: 100;
        margin-top: 0px;
    }
    .our-stories{
        font-size:50px !important;
    }
    .about-us-description{
        font-size:15px ;
    }
}