@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}

.modal-header {
    color: #fff;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }
  
.modal-title {
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Graphik';
    color: #FF8001;
}

.modal-body {
    padding: 20px;
    font-family: 'Graphik';
    text-align: center;
}

.modal-text {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

.close-icon {
    width: 30px;
    height: 30px;
    background: #000; /* Black background color */
    border: none;
    cursor: pointer;
    color: #fff; /* White text color */
    font-size: 1.2rem;
    position: absolute;
    top: 10px;
    right: 10px; /* Optional: Add padding for better visual appearance */
    border-radius: 50%; /* Optional: Add border-radius for a rounded button */
  }