header{    
    position: fixed;
    top: 0;
    width: 100%;
    /* border-bottom: solid .5px rgb(191, 191, 191); */
    z-index: 100;
    transition: background-color 0.5s, color 0.5s;
}
.night-bg>.dropdown-menu{
    background-color: var(--dark-bg) !important;
}

.night-bg>.dropdown-menu>a:hover{
    background-color: rgb(70, 70, 70);
}
.night-bg>.btn-close{
    background-color: white;
}
.night-text-color>.navbar-toggler-icon{
    background: white !important;
}
.navbar-item{
    text-decoration: none;
}
.day-lang{
    transition: background-color 0.1s, color 0.1s;
    background: white;
    color: black !important;
}
.night-lang{
    transition: background-color 0.1s, color 0.1s;
    background: var(--dark-bg);
    color: var(--dark-text-color) !important;
}
.nav-link{
    color: white !important;
}
.night-mode .navbar-toggler-icon,
.day-mode .navbar-toggler-icon {
    background-color: #fff;
}
header.scrolled {
    background-color: #111111;
    transition: background-color 0.5s, color 0.5s;
}
.lang{
    background: transparent;
    color: white;
    border: none;
    font-weight: 700;
}
.lang-border{
    border-right: 1px solid white;
}