@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}

.contact-heading{
    font-family:'Geologica';
    font-weight: 500;
    font-size: 60px;
    display: flex;
    justify-content: center;
}
.contact-sub-heading{
    font-weight: 100;
    display: flex;
    justify-content: center;
}
.contact-form-container{
    display: flex;
    justify-content: center;
}
.contact-form{
    min-width: 700px;
}
.submit-btn{
    font-size: 17px;
    font-weight: 600;
    border-radius: 30px;
    width: 25%;
    border: black;
    background:  linear-gradient(to bottom, rgb(210, 134, 28), rgb(182, 70, 25));
}
@media screen and (max-width: 700px) {
    .contact-form{
        min-width: 500px;
    }
}

@media screen and (max-width: 570px) {
    .contact-heading{
        font-size: 45px;
    }
    .contact-form{
        min-width: 300px;
    }
}