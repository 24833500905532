a{
    text-decoration: none;
    color: black;
    display: block; /* Make anchor tag a block-level element to fill its container */
    position: relative;
}
.day-theme{
    background-color: white;
}
.night-theme{
    color: var(--dark-text-color);
    background-color: var(--dark-bg);
}
.pricing {
    width: 91.5vw;
    margin: auto;
    padding-top: 100px;
    margin-bottom: 100px;
    border-radius: 40px;
    padding-bottom: 30px;
}
.pricing-heading {
    font-family: 'Geologica';
    font-weight: 800;
    font-size: 90px !important;
    margin-bottom: 20px;
    display: flex;
}
.pricing-description{
    display: "flex";
    justify-content: "center";
    font-size: 20px;
    font-weight:lighter;
}
.pricing-container{
    border-radius: 30px;
    background: linear-gradient(to bottom, #2D4356,#1F6E8C);
    z-index: -3;
}
.price-container-background{
    border-radius: 15px;
    margin: 20px;
}
.price-bg{
    background-color:#DCF2F1;
}

.pricing-package-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-family: 'Geologica';
    font-weight: 500;
    font-size: 27px;
    padding: 10px;
}
.package-feature{
    height: 250px;
    font-weight: lighter;
    padding-top:10px;
    padding-bottom:20px;
    border-top: 1px solid;
    border-bottom: 1px solid;
}
.prices{
    display: flex;
    justify-content: center;
    font-family: 'Geologica';
    font-weight: 100;
    font-size: 20px;
    margin-top: 5px;
    padding: 10px;
    padding-bottom: 20px;
}

@media screen and (max-width: 770px) {
    .pricing {
        width: 100%;
        margin-bottom: 30px;
    }
    .pricing-container{
        border-radius: 0%;
        margin: 0px;
        padding-left: 20px;
    }
    .pricing-heading{
        font-size:50px !important;
    }
    .pricing-description{
        font-size:15px;
    }
    .package-feature{
        overflow: scroll;
    }
}

@media screen and (max-width: 570px) {
    .pricing {
        width: 100%;
        margin-bottom: 30px;
    }
    .pricing-container{
        border-radius: 0%;
        margin: 0px;
        padding-left: 20px;
    }
    .pricing-heading{
        font-size:50px !important;
    }
    .pricing-description{
        font-size:15px ;
    }
    .package-feature{
        min-width: 75vw;
        overflow: scroll;
    }
}