@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}
.service-background{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #FF8000;
}
.service-image{
    position: relative;
    top: 20px;
    left: 30px;
}
.service-name{
    font-family:'Geologica';
    font-weight: 400;
}