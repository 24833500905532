@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}
.client-section{
    border-radius: 40px;
    margin: auto;
    width: 91.5vw;
}
.clients-heading{
    font-family: 'Graphik';
    font-weight: 600;
    font-size: 60px;
    display: flex;
    justify-content: center;
    /* color: white; */
}
.clientLogo{
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.clientName{
    font-family: 'Graphik';

}

@media screen and (max-width: 1026px) {
    .clients-heading{
        font-weight: 600;
        font-size: 40px;
    }
    .client-section{
        width: 95vw;
    }
}

@media screen and (max-width: 700px) {
    .clients-heading{
        font-size: 40px;
    }
    .client-section{
        border-radius: 0px;
        width: 100%;
    }
}
@media screen and (max-width: 570px) {
    .clients-heading{
        font-size: 40px;
    }
    .client-section{
        border-radius: 0px;
        width: 100%;
    }
}