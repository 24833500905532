@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}

.day-service-section,.night-service-section {
    margin: auto;
    padding-top: 40px;
    padding-bottom: 50px;
    padding-bottom: 30px;
    border-radius: 40px;
    width: 91.5vw;
}
.day-service-section{
    background-image: linear-gradient(to right, #42032C, #730a1f);
}
.night-service-section{
    margin: auto;
    color: var(--dark-text);
    background-image: linear-gradient(to left, #567189, #7B8FA1);
    padding-top: 40px;
    padding-bottom: 30px;
    border-radius: 40px;
    width: 91.5vw;
}
.services-heading{
    font-family: 'Graphik';
    font-weight: 600;
    font-size: 60px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1026px) {
    .services-heading{
        font-weight: 600;
        font-size: 40px;
    }
    .service-section{
        width: 95vw;
    }
}

@media screen and (max-width: 700px) {
    .services-heading{
        font-weight: 600;
        font-size: 40px;
    }
    .service-section{
        width: 100%;
    }
    .service-section{
        border-radius: 0px;
    }
}
@media screen and (max-width: 570px) {
    .services-heading{
        font-size: 40px;
    }
    .service-section{
        width: 100%;
    }
    .service-section{
        border-radius: 0px;
    }
}