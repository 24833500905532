.technology-section{
    background: linear-gradient(to bottom, #607274, #B2A59B);
    border-radius: 25px;
    width: 91.5vw;
    margin: auto;
    padding-bottom: 20px;
    margin-bottom: 70px;
}
.technology-container{
    padding: 50px;
}
.technology-heading{
    display: flex;
    justify-content: center;
    font-size: 60px;
    font-weight: 600;
    font-family: 'Graphik';
}
.technology-description{
    display:"flex";
    justify-content:"center";
}
.technologies-subcontainers{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.technology-images-container {
    width: 100%;
    height: auto;
}
.images-container {
    width: 100%; /* Adjust the width as needed */
    max-width: 250px; /* Set a max-width if necessary */
    max-height: 250px;
    display: grid;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    padding: 20px;
    margin: 20px;
    background: #7B8FA1;
    border-radius: 25px;
}
.item img {
    width: 100%;
    height: auto;
}


@media screen and (max-width: 770px) {
    .technology-section {
        width: 100%;
        border-radius: 0px;
    }

    .technology-heading {
        font-size: 40px;
    }

    .images-container {
        margin-left: 0;
    }
}

@media screen and (max-width: 570px) {
    .technology-heading{
        font-size: 40px;
    }
    .item img {
        width: 70%;
        height: auto;
    }
    .images-container {
        margin: 0px;
    }
}