:root{
  --dark-bg: #040D12;
  --dark-text-color:#b3b3b3;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.day-text-color, .day-text-color>a{
  color: black !important;
}
.night-text-color,.night-text-color>a{
  color: var(--dark-text-color) !important;
}
.night-bg{
  background: var(--dark-bg) !important;
  color: white !important;
}
.day-bg{
  background: white;
}