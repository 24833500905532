@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}
.day-project-section,.night-project-section{
    width: 91.5vw;
    margin: auto;
    border-radius: 40px;
    padding-bottom: 30px;

}
.day-project-section{
    background-image: linear-gradient(to left, #2B3467, #13191f);
}
.night-project-section{
    background-image: linear-gradient(to top, #2D4356  , #435B66);

}
.project-heading{
    font-family: 'Graphik';
    font-weight: 600;
    font-size: 60px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    color: white;
}

.swiper {
    width: 90vw;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 350px;
    height: 350px;
}

.swiper-slide img {
    display: block;
    object-fit: cover;
    width: 320px;
    height: 320px;
    border-radius: 10px;
}
.swiper-slide {
    width: 20rem !important;
    height: 20rem !important;
    position: relative;
}

.swiper-slide-shadow-left, .swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

.slider-controler .slider-arrow {
    background: white;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(255, 255, 255, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative !important;
    width: 15rem !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: whitesmoke;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 90% !important;
        transform: translateX(-80%) !important;
    }
    .slider-controler .swiper-button-prev {
        left: 10% !important;
        transform: translateX(-20%) !important;
    }
}

@media screen and (max-width: 1026px) {
    .project-heading{
        font-weight: 600;
        font-size: 40px;
    }
    .project-section{
        width: 95vw;
    }
}

@media screen and (max-width: 700px) {
    .project-section{
        width: 100%;
        border-radius: 0px;
    }
    .project-heading{
        font-weight: 600;
        font-size: 40px;
    }
    .service-section{
        border-radius: 0px;
    }
}
@media screen and (max-width: 570px) {
    .project-section{
        width: 100%;
        border-radius: 0px;

    }
    .project-heading{
        font-size: 40px;
    }
}