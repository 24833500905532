@font-face {
    font-family: 'Graphik';
    src: url('../../../assests/Graphik.ttf') format('truetype');
    /* Add additional font formats here if available */
}
#tsparticles {
    position: absolute;
    width: 100%;
    height: 10px;
    z-index: -10;
}

.main-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: "Graphik";
    /* margin-top: 20px; */
}
.day-main-background-section, .night-main-background-section{
    position: absolute;
    /* opacity: 85%; */
    background-size: cover; /* This will cover the entire container */
    background-position: center; /* Center the background image */
    transition: background-image 2.0s ease-out;
    /* background-image: url('../../../assests/bg3.jpg');  */
    /* background: linear-gradient(-180deg, #b6b6b6,#000000); */
    /* width: 100%; */
    /* height: 100vh; */
    width: 100%;
    height: 100vh;
    /* border-radius: 25px; */
    z-index: -9;
}
/* .night-main-background-section{
    position: absolute;
    opacity: 40%;
    background: linear-gradient(0deg, #ffffff,#959595);
    width: 91.5vw;
    height: 500px;
    border-radius: 25px;
    z-index: -9;
} */

.main-heading{
    opacity: 100%;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: 90px;
    font-family: 'Geologica';
    font-weight: 800;
    color: white;
    z-index: 10;
}
.main-subheading{
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    font-family: 'Graphik';
    color: white;
    font-weight: 400;
}
.getquote{
    width: 200px;
    height: 60px;
    /* background: linear-gradient(-90deg, #2d2d2d,#212529); */
    color: white;
    font-weight: 900;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: large;
    cursor: pointer;
    font-family: 'Graphik';
    border-radius: 50px;
}

@media screen and (max-width: 1026px) {
    .main-section{
        min-height: 85vh;
    }
    .main-container{
        padding-top: 30px;
    }
    .main-background-section{
        width: 95vw;
        height: 400px;
        top:100px;
    }
    .main-heading{
        font-size: 50px;
    }
    .getquote{
        margin-top: 20px;
    }
    .workflowImg{
        width: 500px;
        margin-top: 20px;
    }
}


@media screen and (max-width: 700px) {
    .main-section{
        min-height: 85vh;
    }
    .main-container{
        padding-top: 0px;
    }
    .main-background-section{
        width: 100%;
        height: 430px;
        top:40px;
        border-radius: 0px;
    }
    .main-heading{
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 50px;
    }
    .main-subheading{
        font-size: 20px;
    }
    .workflowImg{
        width: 300px;
        margin-top: 20px;
        margin-left:20px ;
    }
}

@media screen and (max-width: 570px) {
    .main-container{
        padding-top: 50px;
    }
    .main-background-section{
        width: 100%;
        height: 490px;
        top:40px;
        border-radius: 0px;
    }
    .main-heading{
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 50px;
    }
    .main-subheading{
        font-size: 20px;
    }
    .workflowImg{
        width: 300px;
        margin-top: 20px;
        margin-left:20px ;
    }
}