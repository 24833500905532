.project-body{
    padding-left: 60px;
    padding-right: 50px;
}
.project-name{
    margin-top: 80px;
    font-size: 90px;
    font-family: 'Geologica';
    font-weight: 800;
}
.project-image{
    width: 100%;
    height: 60vh;
    object-fit: contain;
}
.project-solution{
    background: linear-gradient(to top, #FF9800,#5F8670);
    border-radius: 25px;
    margin: 20px 20px 20px 0px;
    padding: 30px;
    color: white;
}
.project-description-heading,.project-challange-heading{
    display: flex;
    justify-content: center;
    font-size: 60px;
}
.detail{
    align-items: center;
    font-size: 20px;
    font-weight: lighter;
}
.technology-icon{
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-left: 20px;
}
.project-link{
    padding: 10px;
    border-radius: 30px;
    background: white;
    color: black;
    font-family: "Geologica";
}
@media screen and (max-width: 570px) {
    .project-name{
        margin-top: 90px;
        font-size: 40px;
    }
    .project-details{
        display: block;
    }
    .project-details>h5{
        margin-left: 20px;
    }
    .technology-icon{
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-right: 10px;
    }
}